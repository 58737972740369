import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {isMobile} from 'react-device-detect';
import ProbabilityScaleBar from "./probabilityScaleBar";


const useStyles = makeStyles((theme) => ({
    resultSection: {
        width: '80vw',
        maxWidth: '70em',
        display: "flex",
        flexDirection: "column",
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    outcomeLine: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    outcomeLineMobile: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "space-between"
    },
    outcomeLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '20%',
        textAlign: 'left'
    },
    outcomeLabelMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left'
    },
    outcomeLabelText: {
        fontSize: 'clamp(1rem, 2.5vw, 100%)'
    },
    probabilityScale: {
        width: '80%'
    },
    probabilityScaleMobile: {
    },
    labelDisclaimer: {
        minHeight: '2vh',
        marginTop: '1vh'
    }
}));

// todo Needs restyling for mobile as not all is displayed
export default function ResultSection({outcomes, probabilities, explanationClassIdx, setExplanationClassIdx, allowExplanationChoice}) {
    const classes = useStyles();

    // Filter out outcomes with no label
    let non_empty_probabilities;
    if (typeof probabilities !== 'undefined') {
        non_empty_probabilities = probabilities.filter((item, index) => outcomes[index] != '' )
    }
    const non_empty_outcomes = outcomes.filter(item => item != '')

    return (
        <div className={classes.resultSection}>
            {non_empty_outcomes.map((item, index) => (
                <div item className={isMobile ? classes.outcomeLineMobile : classes.outcomeLine}>
                     {/*explanationClassIdx is in absolute index of labels (not only non-empty labels)*/}
                    <Tooltip title={explanationClassIdx === outcomes.indexOf(item) ? "Current explanation." : "Compute explanation."}
                             placement="left" arrow
                             disableFocusListener={(typeof non_empty_probabilities === 'undefined') || !allowExplanationChoice}
                             disableHoverListener={(typeof non_empty_probabilities === 'undefined') || !allowExplanationChoice}
                             disableTouchListener={(typeof non_empty_probabilities === 'undefined') || !allowExplanationChoice}
                    >
                        <Button
                            className={isMobile ? classes.outcomeLabelMobile : classes.outcomeLabel}
                            color={explanationClassIdx === outcomes.indexOf(item) ? "primary" : "default"}

                              classes={{
                                label: classes.outcomeLabelText, // class name, e.g. `classes-nesting-label-x`
                              }}
                            disabled={typeof non_empty_probabilities === 'undefined'}
                            onClick={() => setExplanationClassIdx(outcomes.indexOf(item))}
                        >
                            {item}
                        </Button>
                    </Tooltip>
                    <div className={isMobile ? classes.probabilityScaleMobile : classes.probabilityScale}>
                        {typeof non_empty_probabilities !== 'undefined' ?
                            <ProbabilityScaleBar value={non_empty_probabilities[index] * 100} disabled={false}/>
                            : <ProbabilityScaleBar value={50} disabled={true}/>}
                    </div>
                </div>
            ))}
            <div className={classes.labelDisclaimer}>
                <Typography variant={'caption'}> Any diagnosis or outcome not listed here will not be evaluated by this
                    model. </Typography>
            </div>
        </div>
    );
};
