import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded"
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import capture from '../utils/capture'


const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none'
    }
}));


export default function CaptureTool({handleCapture}) {
    const classes = useStyles();

    const handleDesktopClick = async _ => {
        const frame = await capture()
        handleCapture(frame)
    }

    const handleSmartphoneInput = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                handleCapture(newUrl)
            }
        }
    }

    return (
        <div>
            <MobileView>
                <input
                    accept="image/*"
                    className={classes.input}
                    id="smartphone-input"
                    type="file"
                    onChange={(e) => handleSmartphoneInput(e.target)}
                />
                <label htmlFor="smartphone-input">
                    <Button
                        variant="contained"
                        aria-label="record picture"
                        component="span"
                    >
                        <PhotoCameraRoundedIcon fontSize="medium" color="default"/>
                    </Button>
                </label>
            </MobileView>
            <BrowserView>
                <Button variant="contained" onClick={handleDesktopClick}>
                    Select Image.
                </Button>
            </BrowserView>

        </div>


    );
}
