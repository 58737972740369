import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ScaledBar from "./baseComponents/scaledBar";
import ProbabilityScaleExplanationTooltip from "./poppers/probabilityScaleExplanationTooltip";


const useStyles = makeStyles((theme) => ({

}));


export default function ProbabilityScaleBar({value, disabled}) {
  const classes = useStyles();

  return (
    <ProbabilityScaleExplanationTooltip
        value={value}
        disabled={disabled}
        enclosed={
            <div>
                <ScaledBar value={value} disabled={disabled}/>
            </div>
        }
    />
  );
}