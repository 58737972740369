import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CaptureTool from "./captureTool";
import ImageCrop from "./imageCrop";
import ImageAnalysis from "./imageAnalysis";
import DropDownMenu from "./baseComponents/dropDownMenu";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  selector: {
    width: 'fit-content',
  },
    selectorContainer: {
        position: 'fixed',
        bottom: 0,
        left: '1em',
        // width: '100%',
    },
  phantom: {
    display: 'block',
      padding: '20px',
      height: '80px',
      // width: '100%',
  }

}));

export default function ModelSelectionSection({selectModel}) {
  const classes = useStyles();

  return (
     <div className={classes.root}>
        <div className={classes.phantom} />
         <div className={classes.selectorContainer}>
             <div className={classes.selector}>
                  <DropDownMenu selectModel={selectModel} />
             </div>
         </div>
     </div>
  );
}
