import React from 'react'

const capture = async () => {
  const canvas = document.createElement("canvas");

  try {
    const captureStream = await navigator.mediaDevices.getDisplayMedia();
    const mediaTrack = captureStream.getTracks()[0]
    const imageCapture = new ImageCapture(mediaTrack)

    // The code below is not working anymore since chrome 68
    // https://github.com/w3c/mediacapture-screen-share/issues/105#issuecomment-700764188
    // return imageCapture.takePhoto().then(function(blob) {
    //     console.log('Took photo:', blob);
    //     return URL.createObjectURL(blob);
    //   }).catch(function(error) {
    //     console.log('takePhoto() error: ', error);
    //   });

    //  Workaround: probably lower resolution
    return imageCapture.grabFrame()
      .then(function(imageBitmap) {
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
        canvas.classList.remove('hidden');
        const frame_url = canvas.toDataURL("image/png");
        captureStream.getTracks().forEach(track => track.stop());
        return frame_url
      })
      .catch(function(error) {
        console.log('grabFrame() error: ', error);
      });
  } catch (err) {
    console.error("Error: " + err);
  }
};

export default capture;
