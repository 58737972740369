import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import Backdrop from "@material-ui/core/Backdrop";
import {Box, createMuiTheme, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import InfoButton from "./baseComponents/infoButton";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    background: '#000000c4',
    display: 'flex',
    flexDirection: 'column'
  },
  headerTitle: {
    fontStyle: 'italic',
    "&:hover": {
		cursor: 'pointer'
	}
  },
  description: {
   width: '60%',
   maxHeight: '80vh',
   marginTop: '10vh',
   textAlign: 'left',
   overflowY: 'scroll',
  },
  signature: {
      fontStyle: 'italic',
      marginTop: '5vh',
      marginBottom: '2vh'
  },
  header: {
      backgroundColor: "#282c34",
      minHeight: "10vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: 'center',
      alignSelf: 'center',
      justifyContent: "center",
      fontSize: "calc(10px + 2vmin)",
      color: "white"
  },
  infoButtonPosition: {
      position: "absolute",
      marginLeft: "80%"
  }
}));

const headerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#fafafa',
    },
    secondary: {
      main: '#90caf9',
    },
  },
});

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(!open);
  };

  return (
      <div>
      <header className={classes.header} onClick={handleClick}>
          <ThemeProvider theme={headerTheme}>
            <div className={classes.headerTitle}><Box color="primary.main">Kassandra</Box></div>
            <div className={classes.infoButtonPosition}>
                <InfoButton />
            </div>
          </ThemeProvider>
      </header>
              <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
            <div className={classes.description}>
            <Typography variant="body1">
            Kassandra demonstrates how to bring machine learning models as close as possible to the clinician. <br/>
            All data is processed directly in the browser, thus no information ever leaves the computer that is used to access the webpage. <br/>
            Furthermore, no direct access to the imaging file is needed as the model can process the screenshot obtained from the screen-sharing window.
            <br/><br/>
            This project is however not all about feasibility, as it should also bring to light the potential dangers
                and pitfalls of machine learning models in clinical practice. Indeed, although they can add useful
                information, they should never substitute clinical and critical judgement.
            <br/><br/>
            If you wish to support this project or wish to see further models hosted in this fashion, please do not hesitate to contact me.
            </Typography>
            </div>
            <div className={classes.signature}>
                <Link variant="inherit" color="inherit" href='https://www.julianklug.com'> Julian Klug </Link>
            </div>
        </Backdrop>
      </div>
  );
}
