import React from 'react';
import './App.css';
import ImageSection from "./components/imageSection";
import Disclaimer from "./components/disclaimer";
import Header from "./components/header";
import ModelSelectionSection from "./components/modelSelectionSection";
import available_models from "./model_functions/available_models";

function App() {
  const [modelOption, setModelOption] = React.useState(available_models[0]);

  return (
    <div className="App">
      <Header/>
      <ImageSection modelOption={modelOption}/>
      <ModelSelectionSection selectModel={setModelOption}/>
      <Disclaimer/>
    </div>
  );
}

export default App;
