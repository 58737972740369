// create model class
export class Model {
    // Base class for all models
    // functions should return error if not implemented

    constructor() {
        this.model_name = "model";
        this.noImplError = new Error("Not implemented");
    }

    // load model weights
    async load_model() {
        throw this.this.noImplError;
    }
    async load_config() {
        throw this.noImplError;
    }

    async preprocess_input() {
        throw this.noImplError;
    }

    async predict() {
        // should return a list 2 elements
        // - classProbabilities: list of objects with class name and probability: {className: 'class_name', probability: 0.5}
        // - detections: lis of all detected objects with class name, probability and bounding box: {className: 'class_name', probability: 0.5, boundingBox: [x1, y1, x2, y2]}
        throw this.noImplError;
    }

    async compute_gradients() {
        throw this.noImplError;
    }

}
