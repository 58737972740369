import * as tf from "@tensorflow/tfjs";
import {makeColor, makeTransparent, scaleBoundingBox} from "./image_utils";

// Set of functions handling displaying / plotting on canvas

export async function displayGrads(grads) {
    //////// display grad image
    const grad_canvas = document.getElementsByClassName("gradimage")[0]
    grad_canvas.style.filter = "blur(0.89rem)"

    // Grads and input image should be of approx same size (except cut edges), but grad image could be force to input image size as such:
    // const input_img_canvas = document.getElementsByClassName("cropped_input_image")[0]
    // const reshaped_grads = tf.image.resizeBilinear(tf.expandDims(grads, 2), [input_img_canvas.height, input_img_canvas.width])
    // await tf.browser.toPixels(reshaped_grads, grad_canvas);

    await tf.browser.toPixels(grads, grad_canvas);

    const grad_ctx = grad_canvas.getContext('2d');
    const d = grad_ctx.getImageData(0, 0, grad_canvas.width, grad_canvas.height);

    makeColor(d.data);
    grad_ctx.putImageData(d, 0, 0);
    makeTransparent(d.data)
    grad_ctx.putImageData(d, 0, 0);
}

export function displayBoundingBoxes(detections, current_model) {
    // display bounding boxes
    const targetCanvas = document.getElementsByClassName("gradimage")[0]
    const ctx = targetCanvas.getContext('2d');
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'beige'
    ctx.font = '0.7em Calibri';
    ctx.fillStyle = 'beige';
    for (let i = 0; i < detections.length; i++) {
        // do not display bounding boxes with low confidence and for predictions of class "Text"
        if (detections[i].probability < current_model.model_config.confidence_threshold ||
            detections[i].className === 'Text') {
            continue
        }
        const bbox = detections[i].boundingBox;
        const [x1, y1, x2, y2] = bbox;

        // convert bbox to canvas coordinates
        const [model_h, model_w] = [current_model.model_config.input_size[2], current_model.model_config.input_size[3]]
        // transpose from model to target canvas coordinates
        let transposed_bbox = scaleBoundingBox(x1, y1, x2, y2, model_h, model_w, targetCanvas.height, targetCanvas.width)
        const [x, y, width, height] = transposed_bbox;
        // display fist letter of every word of className with probability
        const firstLettersClassName = detections[i].className.split(/\s/).reduce((response,word)=> response+=word.slice(0,1) + '.','')
        const text = firstLettersClassName + ' ' + detections[i].probability.toFixed(2);
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();
        let text_y = y;
        let text_x = x;
        // if in top half of image display text below bbox, otherwise above
        if (y < targetCanvas.height / 2) {
            ctx.textBaseline = 'top';
            text_y = y + height + 2;
        } else {
            ctx.textBaseline = 'bottom';
            text_y = y - 2;
        }
        ctx.fillText(text, text_x, text_y);
    }
}

export function invertInputImage() {
    if (document.getElementsByClassName("cropped_input_image")[0].style.filter === 'invert(100%)') {
        document.getElementsByClassName("cropped_input_image")[0].style.filter = "invert(0%)"
    } else {
        document.getElementsByClassName("cropped_input_image")[0].style.filter = "invert(100%)"
    }
}