import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {ThemeProvider} from "@material-ui/styles";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function InfoButton() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const buttonProps = {
    fontSize: isSmallScreen ? "default" : "large"
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
      <IconButton aria-label="info" color="secondary">
        <InfoOutlinedIcon {...buttonProps}/>
      </IconButton>
      </ThemeProvider>
    </div>
  );
}