import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cropTool: {
    }
}));

const ImageCrop = ({src, handleCrop}) => {
  const [imageRef, setImageRef] = useState(undefined);
  const [croppedImageURL, setCroppedImageURL] = useState(undefined);
  const [cropSetting, setCropSetting] = useState({
      unit: '%',
      width: 30,
      height: 30,
    });

  const classes = useStyles();

  // If you setState the crop in here you should return false.
  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onCropComplete = crop => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCropSetting(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'cropped.jpeg'
      );
      setCroppedImageURL( croppedImageUrl );
    }
  }

  const handleClick = () => {
    handleCrop(croppedImageURL, cropSetting.width, cropSetting.height)
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    });
  }

    return (
      <div>
        <div>
        <ReactCrop
            className={classes.cropTool}
            src={src}
            crop={cropSetting}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        </div>

        <Button variant="contained" onClick={handleClick}>
            Crop.
        </Button>
      </div>
    );

}

export default ImageCrop;