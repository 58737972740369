import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {isMobile} from 'react-device-detect';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    disclaimerBox: {
        background: '#ffffffed',
    },
    disclaimerText: {
        color: '#d87600'
    }
}));

export default function Disclaimer() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
     <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning" color={'white'}
            className={classes.disclaimerBox}
          >
        {/*<span className={classes.disclaimerText}> Kassandra is a research project and not ready for clinical use yet. {isMobile ? "Optimized for desktop." : ""} </span>*/}
        <span className={classes.disclaimerText}> Kassandra complements medical judgment and should never be a substitute. {isMobile ? "Optimized for desktop." : ""} </span>
      </Alert>
    </Snackbar>
  );
}
