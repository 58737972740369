import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    analysisButtonSection: {
        marginTop: '0.1vh'
    }
}));

export default function AnalysisButtonSection({modelReady, analyseFunction, invertFunction}) {
  const classes = useStyles();
  return (
        <Grid container className={classes.analysisButtonSection}
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
        >
            <Grid item>
            {modelReady ?
                (
                    <Button variant="contained" onClick={analyseFunction}>
                        Analyse.
                    </Button>
                ): (
                    <Button variant="contained" disabled={true}>
                        Analyse.
                    </Button>
                )
            }
            </Grid>
            <Grid item>
            <Button variant="contained" onClick={invertFunction}>
                Invert.
            </Button>
            </Grid>
        </Grid>
  );
}
