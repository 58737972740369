import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CaptureTool from "./captureTool";
import ImageCrop from "./imageCrop";
import ImageAnalysis from "./imageAnalysis";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '2vh'
  },

  imgContainer: {
    maxWidth: "90vw",
    marginTop: '2vh'
  },
}));

export default function ImageSection({modelOption}) {
  const [initialImage, setInitialImage] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [croppedImageSettings, setCroppedImageSettings] = useState(undefined);
  const classes = useStyles();

  const handleCapture = async (capture) => {
    setInitialImage(capture)
    setCroppedImage(undefined)
  }

  const handleCrop = (cropped, width, height) => {
    setCroppedImageSettings({width, height})
    setCroppedImage(cropped)
  }

  return (
     <div className={classes.root}>
       <CaptureTool handleCapture={handleCapture}/>
       <div className={classes.imgContainer}>
        {initialImage && !croppedImage ?
            <ImageCrop src={initialImage} handleCrop={handleCrop}/> : null
        }
        {croppedImage ?
            <ImageAnalysis
                modelOption={modelOption}
                imageURL={croppedImage}
               width={croppedImageSettings.width}
               height={croppedImageSettings.height}
            />: null
        }
       </div>
     </div>
  );
}
