import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),

    },
    arrow: {
        fontSize: 20,
        color: "#f5f5f9",
    }
}))(Tooltip);


export default function ProbabilityScaleExplanationTooltip({value, title, content, enclosed, placement, disabled}) {

    const default_explanation_title = 'Uncertain Probability'
    let explanation_title = default_explanation_title

    const default_explanation_content = 'In the middle region, the model can not determine if the diagnosis is present.'
    let explanation_content = default_explanation_content

    let explanation_placement = 'bottom'

    if (value < 35) {
        explanation_title = 'Low Probability'
        explanation_content = 'In this zone, the model thinks this diagnosis is probably not present on the image.'
        explanation_placement = 'left-start'
        // explanation_placement = isSmallScreen ? "bottom-start" : 'left-start'
    }

    if (value > 65) {
        explanation_title = 'High Probability'
        explanation_content = 'In this area, the model believes that the diagnosis is likely to be present.'
        explanation_placement = 'right-start'
        // explanation_placement = isSmallScreen ? 'bottom-end' : 'right-start'
    }

    if (typeof title !== 'undefined') {
        explanation_title = title
    }
    if (typeof content !== 'undefined') {
        explanation_content = content
    }
    if (typeof placement !== 'undefined') {
        explanation_placement = placement
    }


    return (
        <div>
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{explanation_title}</Typography>
                        {explanation_content}
                    </React.Fragment>
                }
                placement={explanation_placement}
                arrow
                disableFocusListener={disabled}
                disableHoverListener={disabled}
                disableTouchListener={disabled}
                enterTouchDelay={0}
            >
                {enclosed}
            </HtmlTooltip>
        </div>
    );
}