import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ResultSection from "./resultSection";
import {CircularProgress} from "@material-ui/core";
import AnalysisButtonSection from "./analysisButtonSection";
import ImageExplanationPopover from "./poppers/imageExplanationPopover";
import Backdrop from "@material-ui/core/Backdrop";
import {displayBoundingBoxes, displayGrads, invertInputImage} from "../utils/display_utils";


const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
        maxWidth: '100%'
    },
    imageSection: {
    },
    viewbox: {
        position: 'relative',
        borderColor: 'black',
        borderWidth: '2px',
        padding: '0px',
        minHeight: '33vh',
          display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    layer: {
        height: '100%',
        maxHeight: '700px',
        position: 'absolute',
        imageRendering: 'pixelated',
    },
    loadingSpinner: {
        marginTop: '5vh',
    },
    computationBackdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    computationBackdropContent: {
        fontSize: '2vh'
    }
}));


export default function ImageAnalysis({modelOption, imageURL, width, height}) {
    const [model, setModel] = useState(undefined);
    const [modelReady, setModelReady] = useState(false);
    const [dx, setDx] = useState(undefined);
    const [explanationClassIdx, setExplanationClassIdx] = useState(undefined);
    const [inputImg, setInputImg] = useState(undefined);
    const [inputTensor, setInputTensor] = useState(undefined);
    const [anchorPopoverExplanation, setAnchorPopoverExplanation] = React.useState(null);
    const [popoverExplanationContent, setPopoverExplanationContent] = React.useState('originalImage');
    const [imageAnalysisRunning, setImageAnalysisRunning] = React.useState(false);

    const classes = useStyles();
    width = Math.floor(width)
    height = Math.floor(height)
    const current_model = new modelOption.model()

    // TODO: check if model has changed
    // Preload model only on first render
    useEffect(() => {
        console.log('current_model', current_model)
        current_model.load().then((p) => {
            setModel(current_model)
            setModelReady(true)
        })
    }, [])


    useEffect(() => {
        // Draw cropped image
        const input_img_canvas = document.getElementsByClassName("cropped_input_image")[0].getContext('2d');
        const grad_img_canvas = document.getElementsByClassName("gradimage")[0].getContext('2d');
        const img = new Image();   // Create new img element
        img.src = imageURL
        img.onload = () => {
            input_img_canvas.canvas.width = width
            input_img_canvas.canvas.height = height
            // grad and input images have to be displayed at same canvas size
            grad_img_canvas.canvas.width = width
            grad_img_canvas.canvas.height = height
            input_img_canvas.drawImage(img, 0, 0, width, height);
        };
        setInputImg(img)
    }, [])

    // compute gradients only if explanationClassIdx changes (should be after paint of probabilities)
    // first computation is based on max probability, then user can choose
    useEffect(() => {
        if (typeof explanationClassIdx === 'undefined') {
            return
        }
        if (current_model.display_gradients === true) {
            computeGradients(inputTensor, explanationClassIdx)
        }
    }, [explanationClassIdx])


    useEffect(() => {
        if (imageAnalysisRunning == false) {
            return
        }
        analyseImage()
    }, [imageAnalysisRunning])


    const computeGradients = async (tensor, class_idx) => {
        const grads = await model.compute_gradients(tensor, class_idx)
        await displayGrads(grads)
        setPopoverExplanationContent('gradCamOverlay')
        return true
    }

    const startImageAnalysis = async () => {
        setImageAnalysisRunning(true)
    }

    const analyseImage = async () => {
        if (modelReady === false) {
            console.log('Model not defined')
            return
        }

        let reshaped_tensor = await model.preprocess_input(inputImg)
        setInputTensor(reshaped_tensor)

        const [predicted_class_probabilities, predicted_detections] = await model.predict(reshaped_tensor)
        console.log('predicted_class_probabilities', predicted_class_probabilities)
        console.log('predicted_detections', predicted_detections)
        setDx(predicted_class_probabilities)

        const max_class_idx = model.get_top_class_index(predicted_class_probabilities)
        setExplanationClassIdx(max_class_idx)

        // display bounding boxes
        if (predicted_detections.length > 0) {
            displayBoundingBoxes(predicted_detections, current_model)
            setPopoverExplanationContent('boundingBoxes')
        }

        setImageAnalysisRunning(false)
        return true
    }

    const handlePopoverOpen = (event) => setAnchorPopoverExplanation(event.currentTarget);
    const handlePopoverClose = () => setAnchorPopoverExplanation(null);
    const popoverOpen = Boolean(anchorPopoverExplanation);

    return (
        <div>
            <div className={classes.imageSection}>
                <div className={classes.viewbox}>
                    <canvas className={`${classes.layer} cropped_input_image`}></canvas>
                    <canvas className={`${classes.layer} gradimage`}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                    ></canvas>
                </div>
                <ImageExplanationPopover
                    open={popoverOpen} anchorEl={anchorPopoverExplanation} handlePopoverClose={handlePopoverClose}
                    explanationSituation={popoverExplanationContent}
                    targetDx={(typeof dx !== 'undefined' && typeof explanationClassIdx !== 'undefined') ? dx[explanationClassIdx].className : ''}
                />
            </div>

            <AnalysisButtonSection modelReady={modelReady}
                                   analyseFunction={startImageAnalysis} invertFunction={invertInputImage}/>

            <div>
                {/* Once model config with associated labels is available, display it*/}
                {(modelReady) ?
                    <div>
                        {/* Pre-Display labels and once results are available, add them to display */}
                        {(typeof dx === 'undefined') ?
                            <ResultSection outcomes={model.get_labels()}
                                           setExplanationClassIdx={()=>{}}
                            />
                            // To be sure final labels are in exactly the same order as the probabilities use dx dict here
                            // (although model labels and dx should have same label order)
                            : <ResultSection outcomes={dx.map(dx => dx.className)}
                                             probabilities={dx.map(dx => dx.probability)}
                                             explanationClassIdx={explanationClassIdx}
                                             setExplanationClassIdx={setExplanationClassIdx}
                                             allowExplanationChoice={current_model.display_gradients}
                            />
                        }
                    </div>
                    :
                    <div>
                        <CircularProgress color="inherit" className={classes.loadingSpinner} />
                        <p>Model is loading ...</p>
                    </div>
                }
            </div>
            {/*Display a waiting screen whilst computing prediction*/}
            <Backdrop
                className={classes.computationBackdrop}
                open={imageAnalysisRunning}
              >
            <div className={classes.computationBackdropContent}>
                Computing ...
            </div>
          </Backdrop>
        </div>
    );
}
