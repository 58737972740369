import {ChestXrayModel} from './chest_xray_model';
import {PedWristXrayModel} from './ped_wrist_xray_model';

// list of available models
// links model label, data modality, model object
const available_models = [
    {
        'label': 'Chest X-ray model',
        'data_modality': 'Chest X-Ray',
        'model': ChestXrayModel
    },
    {
        'label': 'Pediatric wrist X-ray model',
        'data_modality': 'Pediatric wrist X-Ray',
        'model': PedWristXrayModel
    }
]

export default available_models