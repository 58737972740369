import {jet} from "./colormaps";


export function scaleBoundingBox(x1, y1, x2, y2, h1, w1, h2, w2) {
  // Calculate scaling factors for width and height
  const widthScale = w2 / w1;
  const heightScale = h2 / h1;

  // Scale the coordinates
  const scaledX1 = x1 * widthScale;
  const scaledY1 = y1 * heightScale;
  const scaledX2 = x2 * widthScale;
  const scaledY2 = y2 * heightScale;

  // Calculate the new width and height
  const scaledWidth = scaledX2 - scaledX1;
  const scaledHeight = scaledY2 - scaledY1;

  return [scaledX1, scaledY1, scaledWidth, scaledHeight];
}


/**
 * @function imageUrlFromImageArr
 * @param {Uint8ClampedArray} arr
 * @param {int} w
 * @param {int} h
 * @returns {string}
 */
export function imageUrlFromImageArr(arr, w, h) {
  if(typeof w === 'undefined' || typeof h === 'undefined') {
    w = h = Math.sqrt(arr.length / 4);
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = w;
  canvas.height = h;

  const imgData = ctx.createImageData(w, h);
  imgData.data.set(arr);
  ctx.putImageData(imgData, 0, 0);

  return canvas.toDataURL();
}

function enforceBounds(x) {
    if (x < 0) {
        return 0;
    } else if (x > 1){
        return 1;
    } else {
        return x;
    }
}
function interpolateLinearly(x, values) {
    // Split values into four lists
    var x_values = [];
    var r_values = [];
    var g_values = [];
    var b_values = [];
    for (i in values) {
        x_values.push(values[i][0]);
        r_values.push(values[i][1][0]);
        g_values.push(values[i][1][1]);
        b_values.push(values[i][1][2]);
    }
    var i = 1;
    while (x_values[i] < x) {
        i = i+1;
    }
    i = i-1;
    var width = Math.abs(x_values[i] - x_values[i+1]);
    var scaling_factor = (x - x_values[i]) / width;
    // Get the new color values though interpolation
    var r = r_values[i] + scaling_factor * (r_values[i+1] - r_values[i])
    var g = g_values[i] + scaling_factor * (g_values[i+1] - g_values[i])
    var b = b_values[i] + scaling_factor * (b_values[i+1] - b_values[i])
    return [enforceBounds(r), enforceBounds(g), enforceBounds(b)];
}

export function makeColor(data) {
    for (var i = 0; i < data.length; i+= 4) {
        var color = interpolateLinearly(data[i]/255, jet);
        data[i] = Math.round(255*color[0]); // Invert Red
        data[i+1] = Math.round(255*color[1]); // Invert Green
        data[i+2] = Math.round(255*color[2]); // Invert Blue
    }
    return data
}

export function makeTransparent(pix) {
        for (var i = 0, n = pix.length; i <n; i += 4) {
    //		var r = pix[i],
            let g = pix[i+1];
    //		b = pix[i+2];

            if (g < 20){
                // If the green component value is higher than 150
                // make the pixel transparent because i+3 is the alpha component
                // values 0-255 work, 255 is solid
                pix[i + 3] = 0;
            }
        }
}