import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Popover} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
    paper: {
    padding: theme.spacing(1),
    maxWidth: '40ch'
  },
}));

export default function ImageExplanationPopover({open, anchorEl, handlePopoverClose, explanationSituation, targetDx}) {
  const classes = useStyles();

  return (
    <Popover
      open={open}
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
     onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {(explanationSituation === 'originalImage') ?
      'The original image obtained by screen capture. Please verify that the quality is sufficient and that no relevant image parts have been cropped off.'
          : null
      }
     {(explanationSituation === 'gradCamOverlay') ?
      `The blue overlay highlights regions to which the model pays most attention when looking for the diagnosis "${targetDx}". If this does not seem to make sense, please do not trust the model's output.`
         : null
      }
      {(explanationSituation === 'boundingBoxes') ?
          `The bounding boxes delineate the detected lesions. Associated probability is displayed. If this does not seem to make sense, please do not trust the model's output.`
         : null
      }
    </Popover>
  );
}