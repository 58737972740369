import React, { useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import available_models from "../../model_functions/available_models";


export default function DropDownMenu({selectModel}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    selectModel(available_models[index])
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Model selection"
        // Semi-transparent and rounded corners
        sx={{
            bgcolor: '#ffffffc9',
            borderRadius: 2,
            paddingTop: 0,
            paddingBottom: '1em',
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="Model"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx ={{
            '&:hover': {
                borderRadius: 'inherit',
            },
          }}
        >
          <ListItemText
            primary="Model"
            secondary={available_models[selectedIndex].data_modality}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {available_models.map((model_option, index) => (
          <MenuItem
            key={model_option.data_modality}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {model_option.data_modality}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
