import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(4),
  },
  rail: {
    height: theme.spacing(4),
  },
  track: {
    height: theme.spacing(4),
  },
  mark: {
    height: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  markLabel: {
  },
  thumb: {
    display: 'none',
  },
  tooltip: {
        margin: "0",
  },
}));


const ScaledBar = ({
  value, disabled
}) => {
  const classes = useStyles();
  return (
    <Slider
      value={value}
      step={10}
      min={0}
      max={100}
      marks
      valueLabelDisplay="off"
      disabled={disabled}
      classes={{
        root: classes.root,
        rail: classes.rail,
        track: classes.track,
        thumb: classes.thumb,
        mark: classes.mark,
        markLabel: classes.markLabel,
      }}
    />
  );
};
export default ScaledBar;